import React from "react"
import MainMenu from "./menu-main"
import { Box } from "theme-ui"

const Footer = () => {

  return (
    <Box
      as={`footer`}
      sx={{
        backgroundColor: `black`,
        py: 6,
        px: 3,
      }}
    >
      <MainMenu />
    </Box>
  )

}

export default Footer