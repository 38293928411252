import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import getSlug from "speakingurl"

const MenuMain = () => {

  const data = useStaticQuery(
    graphql`
      query MenuMain {
        dataJson(slug: {eq: "menu-main"}) {
          fragment_1
          fragment_2
          fragment_3
          fragment_4
          fragment_5
        }
      }
    `
  )

  const dataFragments = data.dataJson

  const contentMenu = [
    [ getSlug(dataFragments.fragment_1), dataFragments.fragment_1 ],
    [ getSlug(dataFragments.fragment_2), dataFragments.fragment_2 ],
    [ getSlug(dataFragments.fragment_3), dataFragments.fragment_3 ],
    [ getSlug(dataFragments.fragment_4), dataFragments.fragment_4 ],
    [ getSlug(dataFragments.fragment_5), dataFragments.fragment_5 ]
  ]

  return (
    <Box
      as='div'
      className="menu"
      sx={{
        backgroundColor: `black`,
        "a[aria-current]": {
          color: 'secondary',
        },
        ".menu-items": {
          margin: 0,
          padding: 0,
          flexDirection: [`column`, `initial`],
        },
        li: {
          listStyle: `none`,
          pr: 3,
        },
        a: {
          borderBottom: `none`,
          color: `white`,
          display: `block`,
          fontSize: 1,
          py: [2, 1],
          ":hover": {
            color: `white`,
            borderBottom: theme => `1px solid ${theme.colors.gray[1]}`,
          }
        },
        "a[aria-current='page']": {
          color: `white`,
          borderBottom: theme => `1px solid ${theme.colors.gray[1]}`,
        }
      }}
    >
      <Box
        as="nav"
        aria-label="Primary Navigation"
      >
        <Flex
          as="ul"
          className="menu-items"
        >
          {contentMenu.map( (item, i) => (
            <li key={i}>
              <Themed.a
                as={Link}
                to={`/${item[0]}`}
                rel="bookmark"
              >
                {item[1]}
              </Themed.a>
            </li>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default MenuMain